import React from 'react';
import { Layout, Seo } from 'components';
import { Container, Col, Row } from 'react-grid-system';
import { HeroPatients } from 'components/hero';
import { HeroCtaPatient } from 'components/cta';

import imgIcon1 from 'images/patient/icons/icon-savings-card.png';
import imgIcon2 from 'images/patient/icons/icon-helpful-info.png';

// import page style
import './index.scss';

const IndexPage = () => {
	return (
<Layout indication='patient' className='patient-home' noShowStickyIsi={false}>
			<Seo pageTitle='Official Site for Patients & Caregivers' />

			<div className='body-content-container'>
				<div className='body-content'>
					<div className='hero-section'>
						<HeroPatients heroImgAlt='page hero' className='sub-page-hero'>
						<h1>Learn more about <a href="/support-materials/" className="bold-link">OxyContin ›</a></h1>
					</HeroPatients>
					</div>

					<Container fluid>
						<Row>
							<Col md={12} lg={6} className='no-gutter'>
								<HeroCtaPatient
									className='blue-cta'
									linkUrl='/savings-program/'
									imgIcon={imgIcon1}
									ctaTitle='How to Save'
									ctaCopy='Find out if you are eligible to save on your prescription'
									linkText='View Savings Program >'
								/>
							</Col>
							<Col md={12} lg={6} className='no-gutter'>
								<HeroCtaPatient
									className='green-cta'
									linkUrl='/support-materials/'
									imgIcon={imgIcon2}
									ctaTitle='Helpful Information'
									ctaCopy='Materials to help you understand your treatment'
									linkText='Learn More >'
								/>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</Layout>
	);
};

export default IndexPage;

